var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-8"},[_c('h1',{staticClass:"text-center"},[_vm._v("งานของฉัน")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('span',[_vm._v("ค้นหา")]),_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersOrder,"items":_vm.itemsData,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status == 'รอชำระเงิน')?_c('v-btn',{attrs:{"text":""}},[_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" ยืนยันรับงาน ")])],1):_c('v-btn',{attrs:{"text":""}},[_c('v-chip',{attrs:{"color":"success","dark":""}},[_vm._v(" แชท ")])],1)]}},{key:"item.editItems",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.goToEdit(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'รอชำระเงิน')?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(_vm._s(item.status))]):_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertDate(item.updatedAt)))])]}},{key:"item.requestDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertDateService(item.requestDate))+" "+_vm._s(item.requestTime))])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }